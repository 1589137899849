import Vue from "vue";

/*TITLE*/
document.title = "Fresneda Stella | Porcelanosa PARTNERS";
/*PROJECT TYPE*/
Vue.prototype.$typeProject = "PARTNERS";

/*HERO*/
Vue.prototype.$title = "Fresneda Stella";
Vue.prototype.$subtitle = "BY CONSTRUCTORA LOS ÁLAMOS";

/*INTRO*/
Vue.prototype.$promoter = "Fresneda Stella";
Vue.prototype.$introSubtitle = "BY CONSTRUCTORA LOS ÁLAMOS";

/*GALLERY*/
Vue.prototype.$image_sittingroom = "salon-vela-grey_web--20230113100131.jpg";
Vue.prototype.$image_kitchen = "cocina-vela-smokedcrystal-whitenogal-moonblanco-nubolxtone-bottega-caliza_web--20230113100114.jpg";
Vue.prototype.$image_bath1 = "banyo-principal-vela-greyvela-greynuba-white-nature_web--20230113100127.jpg";
Vue.prototype.$image_bath2 = "banyo-secundario-bianco-carrarabianco-carraradeco-bremen-roble-natural_web--20230113100100.jpg";
Vue.prototype.$image_room = "";
Vue.prototype.$image_facade = "";

Vue.prototype.$gallery_images_sittingroom = [
  {
    src: "salon-vela-grey_web--20230113100131.jpg",
  },
  {
    src: "salon-ac5-viena-1l-worner_web--20230113100137.jpg",
  },
  {
    src: "salon-ac5-viena-1l-sadnig_web--20230113100155.jpg",
  },
  {
    src: "salon-ac5-viena-1l-eisenhut_web--20230113100101.jpg",
  },
  {
    src: "salon-ac5-viena-1l-coglians_web--20230113100107.jpg",
  },
];

Vue.prototype.$gallery_images_kitchen = [
  {
    src: "cocina-vela-smokedcrystal-whitenogal-moonblanco-nubolxtone-bottega-caliza_web--20230113100114.jpg",
  },
  {
    src: "cocina-vela-greycrystal-whiteeucaliptus-blondcoolxtone-moon-white_rgb--20230113100123.jpg",
  },
  {
    src: "cocina-vela-greycrystal-whiteeucaliptus-blondblanco-nubolxtone-bottega-acero_web--20230113100133.jpg",
  },
  {
    src: "cocina-vela-blackcrystal-whiteetimoe-landbisonxtone-bottega-acero_rgb--20230113100110.jpg",
  },
];

Vue.prototype.$gallery_images_bath1 = [
  {
    src: "banyo-principal-vela-greyvela-greynuba-white-nature_web--20230113100127.jpg",
  },
  {
    src: "banyo-principal-vela-naturalvela-naturalcalacatta-gold-nature_web--20230113100132.jpg",
  },
];

Vue.prototype.$gallery_images_bath2 = [
  {
    src: "banyo-secundario-bianco-carrarabianco-carraradeco-bremen-roble-natural_web--20230113100100.jpg",
  },
];

Vue.prototype.$gallery_images_room = [
];

Vue.prototype.$gallery_images_facade = [
];

/*GALLERY GRID*/
Vue.prototype.$gallery_grid_sittingroom = "6";
Vue.prototype.$gallery_grid_kitchen = "6";
Vue.prototype.$gallery_grid_bath1 = "3";
Vue.prototype.$gallery_grid_bath2 = "3";
Vue.prototype.$gallery_grid_room = "12";
Vue.prototype.$gallery_grid_facade = "12";

/*TOUR VIRTUAL*/
Vue.prototype.$360 = "https://360.porcelanosapartners.com/FRESNEDA_STELLA/";

/*3D FILM*/
Vue.prototype.$3dFilm = "https://www.youtube.com/embed/_fcDtzssumM";

/*CATALOG*/
Vue.prototype.$catalog = true;

/*SPACES*/
Vue.prototype.$spaces = "";

/*PROMOTION LOGO*/
Vue.prototype.$promotion_logo = true;

/*PROMOTER LOGO*/
Vue.prototype.$promoter_logo = true;

/*CONTACT*/
Vue.prototype.$promoter_address = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=camino%20la%20lloridosa%20la%20fresneda",
    text: "Camino la Lloridosa, La Fresneda",
  },
];

Vue.prototype.$showroom = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=plaza%20mayor%20s%20n%20la%20fresneda",
    text: "Plaza Mayor S/N, La Fresneda",
  },
];

Vue.prototype.$contact = [
  {
    icon: "mdi-phone",
    link: "telf:985233800",
    text: "985233800",
  },
  {
    icon: "mdi-email",
    link: "mailto:comercial@alamos.es",
    text: "comercial@alamos.es",
  },
];
